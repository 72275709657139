import React from 'react';
import {
  FaJenkins,
  // FaGrav,
  FaPalfed,
  FaPeopleCarry,
  FaChartLine,
  FaCartPlus,
  FaConciergeBell
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
export default function InformationHome() {
  return (
    <div className="py-9 flex flex-wrap justify-center items-center">
      <Link to="/customers">
        <div className="border-2 mx-4 my-3 rounded-2xl w-48 h-48 p-2 flex flex-col justify-center items-center bg-indigo-500 text-white">
          <FaJenkins className="text-6xl rounded-3xl" />
          <h2 className="mt-2 text-2xl">CLIENTES</h2>
          <p className="text-justify m-0 text-base">
            Sección para gestionar clientes; crear, actualizar y eliminar
          </p>
        </div>
      </Link>
      <Link to="/orders">
        <div className="border-2 mx-4 my-3 rounded-2xl w-48 h-48 p-2 flex flex-col justify-center items-center bg-purple-500 text-white">
          <FaCartPlus className="text-6xl rounded-3xl" />
          <h2 className="mt-2 text-2xl">ORDENES</h2>
          <p className="text-justify m-0 text-base">
            Seccióna para gestionar ordenes; crear y actualizar
          </p>
        </div>
      </Link>
      {/* <Link to="/vendors">
        <div className="border-2 mx-4 my-3 rounded-2xl w-48 h-48 p-2 flex flex-col justify-center items-center bg-purple-500 text-white">
          <FaGrav className="text-6xl rounded-3xl" />
          <h2 className="mt-2 text-2xl">PROVEEDORES</h2>
          <p className="text-justify m-0 text-base">
            Sección para gestionar proveedores; crear, actualizar y eliminar
          </p>
        </div>
      </Link> */}
      <Link to="/products">
        <div className="border-2 mx-4 my-3 rounded-2xl w-48 h-48 p-2 flex flex-col justify-center items-center bg-green-500 text-white">
          <FaPalfed className="text-6xl rounded-3xl" />
          <h2 className="mt-2 text-2xl">PRODUCTOS</h2>
          <p className="text-justify m-0 text-base">
            Sección para gestionar productos; crear, actualizar y eliminar
          </p>
        </div>
      </Link>
      <Link to="/orders">
        <div className="border-2 mx-4 my-3 rounded-2xl w-48 h-48 p-2 flex flex-col justify-center items-center bg-pink-500 text-white">
          <FaPeopleCarry className="text-6xl rounded-3xl" />
          <h2 className="mt-2 text-2xl">PEDIDOS</h2>
          <p className="text-justify m-0 text-base">
            Sección para gestionar pedidos; crear, actualizar y eliminar
          </p>
        </div>
      </Link>
      <Link to="/statistics">
        <div className="border-2 mx-4 my-3 rounded-2xl w-48 h-48 p-2 flex flex-col justify-center items-center bg-yellow-500 text-white">
          <FaChartLine className="text-6xl rounded-3xl" />
          <h2 className="mt-2 text-2xl">RESUMEN</h2>
          <p className="text-justify m-0 text-base">
            Sección para ver un resumen de movientos, entregas y demas...
          </p>
        </div>
      </Link>
      <Link to="/commands">
        <div className="border-2 mx-4 my-3 rounded-2xl w-48 h-48 p-2 flex flex-col justify-center items-center bg-blue-900 text-white">
          <FaConciergeBell className="text-6xl rounded-3xl" />
          <h2 className="mt-2 text-2xl">COMANDAS</h2>
          <p className="text-justify m-0 text-base">
            Sección para el listado de comandas o pedidos por entregar...
          </p>
        </div>
      </Link>
    </div>
  );
}
