import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { post } from 'api/AsyncHttpRequest';

import LinearProgress from '@material-ui/core/LinearProgress';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));
export default function ButtonUploadInputs() {
  const classes = useStyles();
  const urlSaveNewAllProducts = process.env.REACT_APP_API_BASE_URL;
  //const [fileCsv, setFileCsv] = useState()
  const [nameCsv, setNameCsv] = useState();
  const [fileCsvTex, setFileCsvTex] = useState(null);
  const [saving, setSaving] = useState(false);
  const [messageSucces, setMessageSucces] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const respponseCallback = (response) => {
    setSaving(false);
    if (response?.data?.data === true) {
      setMessageSucces(true);
      setMessageError(false);
    } else {
      setMessageError(true);
      setMessageSucces(false);
    }
    setTimeout(() => {
      setMessageError(false);
      setMessageSucces(false);
    }, 20000);
  };

  const getValues = (text, position) => {
    let todo = text.split(',');
    return todo[position];
  };
  const parseCSV = (text) => {
    // Obtenemos las lineas del texto
    let lines = text.replace(/\r/g, '').split('\n');
    let val = lines.forEach((line) => {
      if (line !== '') {
        let values = {
          id_products_input: 1,
          cantidad: parseInt(getValues(line, 0)),
          costo: parseInt(getValues(line, 1)),
          id_product: getValues(line, 2),
        };
        return values;
      }
    });
    // return lines.map((line) => {
    //   // Por cada linea obtenemos los valores
    //   if (line !== '') {
    //     let values = {
    //       id_products_input: 1,
    //       cantidad: parseInt(getValues(line, 0)),
    //       costo: parseInt(getValues(line, 1)),
    //       id_product: getValues(line, 2),
    //     };
    //     return values;
    //   }
    // });
    return val;
  };

  const handlerSaveAll = (e) => {
    let file = e.target.files[0];
    setNameCsv(e.target.files[0].name);
    let reader = new FileReader();
    reader.onload = (e) => {
      let lines = parseCSV(e.target.result);
      lines.pop();
      setFileCsvTex(lines);
      //saveAllProducts(lines)
    };
    reader.readAsBinaryString(file);
  };

  const handleSaveAllProducts = () => {
    setSaving(true);
    post(
      `${urlSaveNewAllProducts}products/api/productsaddinputall`,
      fileCsvTex,
      respponseCallback
    );
  };

  return (
    <div className={`${classes.root} mb-2`}>
      <p
        className="text-red-800 p-0"
        style={{ fontSize: '10px', margin: '0px' }}
      >
        * Importante: el archivo a subir no debe tener cabeceras en las columnas
      </p>
      <input
        accept=".csv"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={(e) => handlerSaveAll(e)}
      />
      <label htmlFor="contained-button-file">
        <Button
          disabled={saving}
          htmlFor="contained-button-file"
          variant="contained"
          color="default"
          className={classes.button}
          component="span"
          startIcon={<CloudUploadIcon />}
        >
          Subir productos
        </Button>
      </label>
      <Button
        disabled={saving}
        htmlFor="contained-button-file"
        variant="contained"
        color="primary"
        className={classes.button}
        component="span"
        onClick={handleSaveAllProducts}
      >
        Guardar
      </Button>
      <div className="font-bold mt-2" id="info">
        {nameCsv}
      </div>
      {saving && <LinearProgress />}
      {messageSucces && (
        <div className={classes.root}>
          <Alert severity="success">
            <AlertTitle>Excelente</AlertTitle>
            Producto creado — <strong>exitosamente!</strong>
          </Alert>
        </div>
      )}
      {messageError && (
        <div className={classes.root}>
          <Alert severity="error">
            <AlertTitle>Algo salio mal</AlertTitle>
            El producto no pudo ser creado —{' '}
            <strong>intenta nuevamente!</strong>
          </Alert>
        </div>
      )}
    </div>
  );
}
