import { useState } from 'react';
import { getParams, getNode } from 'api/AsyncHttpRequest';
import { useSnackbar } from 'notistack';

export function useProvideAuth() {
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState(localStorage.getItem('user') || null);
  const urlBase = process.env.REACT_APP_API_BASE_URL_NODE;

  const callbackResponseUser = (response) => {
    if (response?.type === 'USERNAME_DOES_NOT_EXIST') {
      enqueueSnackbar('El usuario no existe', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      return;
    }
    if (response?.type === 'THE_PASSWORD_IS_NOT_CORRECT') {
      enqueueSnackbar('La contraseña es incorrecta', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      return;
    }
    if (response?.type === 'THIS_COMPANY_DOES_NOT_EXIST') {
      enqueueSnackbar(
        'La empresa no se encuentra registrada en el sistema',
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }
      );
      return;
    }
    if (response?.type === 'THIS_USER_DOES_NOT_BELONG_TO_THIS_COMPANY') {
      enqueueSnackbar(
        'El usuario no existe para esta empresa',
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }
      );
      return;
    }
    if (response?.token) {
      setUser(response?.name);
      localStorage.setItem('companyName', response?.company_name);
      localStorage.setItem('user', response?.name);
      localStorage.setItem('id', response?.id);
      localStorage.setItem('rol', response?.role);
      localStorage.setItem('token', response?.token);
      return;
    }
  };

  const signin = (cb, data) => {
    if (data?.user !== '' && data?.password_h !== '') {
      getParams(`${urlBase}login/`, data, callbackResponseUser);
      cb(data)
    } else {
      enqueueSnackbar('El usuario y la contraseña son requeridos', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };
  const callbackResponseLogout = (response) => {
    // if (response === 'LOGOUT_SUCCESS') {
      setUser(false);
      localStorage.removeItem('user');
      localStorage.removeItem('rol');
      localStorage.removeItem('token');
      localStorage.removeItem('id');
      return;
    // } else {
    //   enqueueSnackbar('Algo salio mal intentalo nuevamente', {
    //     variant: 'warning',
    //     anchorOrigin: {
    //       vertical: 'bottom',
    //       horizontal: 'right',
    //     },
    //   });
    // }
  };

  const signout = (cb) => {
    // getNode(`${urlBase}users/user-logout`, callbackResponseLogout);
    callbackResponseLogout()
    cb()
  };

  return {
    user,
    signin,
    signout,
  };
}
