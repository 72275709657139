import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

export default function InputGetuserController({
  handlerSelectCustmer,
  customersGet,
}) {
  return (
    <div className="flex flex-col justify-start items-start">
      {customersGet?.length > 0 &&
        customersGet?.map((item, index) => (
          <p key={index} className="flex flex-row justify-center items-center">
            <Checkbox
              onClick={() => handlerSelectCustmer(item)}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <strong className="mr-2">{item?.identification_number}</strong>{' '}
            {` ${item?.full_name} `}
          </p>
        ))}
    </div>
  );
}
