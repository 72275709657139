/* eslint-disable no-mixed-operators */
import React, { useState, useEffect, useCallback, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { getNode, putNode } from "api/AsyncHttpRequest";
import TextField from "@material-ui/core/TextField";
import CreateIcon from "@material-ui/icons/Create";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import { useForm } from "react-hook-form";
import getDate from "utils/helpers";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import NotFound from "components/NotFound/NotFound";
import "./customstyles.css";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Swal from "sweetalert2";

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: 0,
  },
  columnActcion: {
    width: 80,
    padding: 0,
  },
  container: {
    minHeight: 650,
    height: "auto",
    width: "100%",
  },
  customAdress: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  button:{
    marginLeft: 10,
    height: 56
  }
});

export const FormUpdate = (props) => {
  const { updateInformation, user, setHiddeFormUpdate, setuserInfo } = props;
  const { enqueueSnackbar } = useSnackbar();
  let hoy = new Date();
  const urlBaseNode = process.env.REACT_APP_API_BASE_URL_NODE;
  const { register, handleSubmit } = useForm();
  const callbackSaveCustomer = (response) => {
    if (response === "CUSTOMER_UPDATE_SUCCESS") {
      enqueueSnackbar("Cliente actualizado con exito", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      setHiddeFormUpdate(false);
      setuserInfo({});
      updateInformation();
    } else {
      enqueueSnackbar("Algo paso al actualizar el cliente intentalo de nuevo", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  const handlerCloseUpdateCustomer = () => {
    setHiddeFormUpdate(false);
    setuserInfo({});
  };
  const onSubmit = (data) => {
    const dataSave = {
      full_name: data?.full_name,
      address_customer: data?.address_customer,
      cellphone: data?.cellphone,
      email: data?.email,
      recurrence: data?.recurrence,
      last_call: getDate(hoy),
      identification_number: user?.identification_number,
      birthday_date: getDate(data?.birthday)
    };

    putNode(
      `${urlBaseNode}customers/updatebyId/${user?.id}`,
      dataSave,
      callbackSaveCustomer
    );
  };
  return (
    <form
      className="flex flex-wrap justify-center items-center border-2 p-5 mx-5 mb-5 border-gray-500 rounded-xl"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="mx-2 my-2 flex flex-col">
        <input
          className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
          placeholder="Nombre"
          defaultValue={user?.full_name}
          {...register("full_name")}
        />
        <label className="text-xs ml-1 pl-1">Nombre completo</label>
      </div>
      <div className="mx-2 my-2 flex flex-col">
        <input
          className="border-2 border-gray-400 text-gray-500 w-56 rounded-md m-1 text-lg p-1"
          defaultValue={user?.document_type}
          placeholder={user?.document_type}
          disabled
        />
          <label className="text-xs ml-1 pl-1">Tipo de documento</label>
      </div>
      <div className="mx-2 my-2 flex flex-col">
        <input
          className="border-2 border-gray-400 text-gray-500 w-56 rounded-md m-1 text-lg p-1"
          defaultValue={user?.identification_number}
          placeholder={user?.identification_number}
          disabled
        />
          <label className="text-xs ml-1 pl-1">Numero de documento</label>
      </div>
      <div className="mx-2 my-2 flex flex-col">
        <input
          className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
          placeholder="Direccion"
          defaultValue={user?.address_customer}
          {...register("address_customer")}
        />
          <label className="text-xs ml-1 pl-1">Dirección</label>
      </div>
      <div className="mx-2 my-2 flex flex-col">
        <input
          className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
          placeholder="Correo"
          defaultValue={user?.email}
          {...register("email")}
        />
          <label className="text-xs ml-1 pl-1">Correo</label>
      </div>
      <div className="mx-2 my-2 flex flex-col">
        <input
          className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
          placeholder="Recurrencia"
          label="Recurrencia"
          defaultValue={user?.recurrence}
          {...register("recurrence")}
        />
          <label className="text-xs ml-1 pl-1">Recurrencia</label>
      </div>
      <div className="mx-2 my-2 flex flex-col">
        <input
          className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
          placeholder="Celular"
          label="Celular"
          defaultValue={user?.cellphone}
          {...register("cellphone")}
        />
          <label className="text-xs ml-1 pl-1">No. Celular</label>
      </div>
      <div className="mx-2 my-2 flex flex-col">
        <input
          className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
          type="date"
          placeholder="Fecha nacimiento"
          label="Fecha nacimiento"
          defaultValue={user?.birthday}
          {...register("birthday")}
        />
          <label className="text-xs ml-1 pl-1">Fecha nacimiento</label>
      </div>
      <div className="mx-2 my-2">
        <Button
          type="submit"
          variant="contained"
          color="cancel"
          onClick={handlerCloseUpdateCustomer}
        >
          Cancelar
        </Button>
      </div>
      <div className="mx-2 my-2">
        <Button type="submit" variant="contained" color="primary">
          Guardar
        </Button>
      </div>
    </form>
  );
};
export default function StickyHeadTable({ setState }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
  const { enqueueSnackbar } = useSnackbar();
  let hoy = new Date();
  const [hiddeFormUpdate, setHiddeFormUpdate] = useState(false);
  const urlBase = process.env.REACT_APP_API_BASE_URL;
  const urlBaseNode = process.env.REACT_APP_API_BASE_URL_NODE;
  const [rows, setRows] = useState({ data: [] });
  const [userInfo, setuserInfo] = useState({});
  const [buscando, setBuscando] = useState(true);
  const [name, setName] = useState("");

  const respponseCallback = useCallback((response) => {
    setBuscando(false);
    setRows(response);
  }, []);

  const updateInformation = useCallback(() => {
    getNode(
      `${urlBaseNode}customers/all-customers?page=${page > 0 ? page +1 : 1}&limit=${rowsPerPage}`,
      respponseCallback
    );
    getNode(
      `${urlBaseNode}customers/all-customers-birthday?page=${page > 0 ? page +1 : 1}&limit=${rowsPerPage}`,
      (res)=>{console.log(res);}
    );
  }, [urlBaseNode, respponseCallback, rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getNode(
      `${urlBaseNode}customers/all-customers?page=${
        newPage + 1
      }&limit=${rowsPerPage}`,
      respponseCallback
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getNode(
      `${urlBaseNode}customers/all-customers?page=${page}&limit=${+event.target
        .value}`,
      respponseCallback
    );
  };

  const respponseCallbackActions = (response) => {
    const responseFormated = JSON.stringify(response);
    const error = JSON.parse(responseFormated);
    if (response?.message === "LAST_CALL_UPDATE_SUCCESS") {
      enqueueSnackbar("Acabamos de llamar este cliente", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      updateInformation();
      return;
    }
    if (response === "CUSTOMER_DELETE_SUCCESS") {
      setName("")
      enqueueSnackbar("Cliente eliminado correctamente", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      updateInformation();
      setState({ succes: true });
    }
    if (error?.message) {
      enqueueSnackbar(
        "Algo salio mal intentalo de nuevo o contacta a soporte",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
      return;
    }
  };

  const handlerEdit = (datauser) => {
    setuserInfo(datauser);
    setHiddeFormUpdate(true);
  };

  const handlerUpdate = (id) => {
    putNode(
      `${urlBaseNode}customers/update/last-call`,
      {
        id: parseInt(id.id),
        last_call: getDate(hoy),
      },
      respponseCallbackActions
    );
  };

  const handlerWpMessage = (item)=>{
    Swal.fire({
      title: `Quieres salir a enviar un mensaje de whatsapp a`,
      text: `${item?.full_name} (+57 ${item?.cellphone.replace(/^\s+|\s+$/g, "")})`,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Enviar',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // `https://wa.me/+${number}?text=Necesito ayuda con mi teléfono`
        const link = `https://wa.me/+${item?.cellphone.replace(/^\s+|\s+$/g, "")}?text=Hola ${item?.full_name}&app_absent=1`;
        window.open(link);
        handlerUpdate(item)
      } else if (result.isDenied) {
        Swal.fire('El mensaje no se enviara y el cliente no sera gestionado', '', 'info')
      }
    })

  }

  const handlerDelet = (id) => {
    const save = window.confirm("Seguro quieres eliminar el cliente");

    if (save) {
      putNode(
        `${urlBaseNode}customers/delete`,
        {
          id: id.id,
          state_customer: 0,
        },
        respponseCallbackActions
      );
    } else {
      enqueueSnackbar("El cliente no se eliminará", {
        variant: "warning",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  const columns = [
    {
      field: "id",
      headerName: "Acción",
      width: 150,
      description:
        "Que desea hacer, editar eliminar o guardar fecha de ultima llamada.",
      renderCell: (params) => (
        <div
          className={
            params?.row?.diference_days >= parseInt(params?.row?.recurrence) + 3
              ? "bg-red-400"
              : params?.row?.called2 === 1
              ? "bg-yellow-400"
              : null
          }
        >
          <button
            onClick={() => handlerEdit(params)}
            className="m-0 mr-1"
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 5 }}
          >
            <CreateIcon />
          </button>
          <button
            onClick={() => handlerDelet(params.value)}
            className="m-0 mr-1"
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 5 }}
          >
            <DeleteForeverIcon />
          </button>
          <button
            onClick={() => handlerUpdate(params.value)}
            className="m-0 mr-1"
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 5 }}
          >
            <PhoneInTalkIcon />
          </button>
        </div>
      ),
    },
    { field: "full_name", headerName: "Nombre completo", width: 250 },
    {
      field: "last_call",
      headerName: "Llamado el",
      description: "Fecha de la ultima llamada realizada al cliente.",
      sortable: false,
      width: 160,
    },
    {
      field: "recurrence",
      headerName: "Recurrencia",
      description: "Cada cuanto debo llamar a este cliente.",
      sortable: false,
      width: 140,
    },
    {
      field: "document_type",
      headerName: "Doc",
      description: "Tipo de documento.",
      width: 90,
    },
    {
      field: "customer_type",
      headerName: "TIP",
      description: "Tipo de cliente.",
      width: 90,
    },
    {
      field: "identification_number",
      description: "Numero de identificacion del cliente o negocio.",
      headerName: "Numero",
      width: 130,
    },
    {
      field: "address_customer",
      headerName: "Dirección",
      description: "Dirección ubicación del negocio.",
      type: "number",
      width: 200,
    },
    {
      field: "email",
      headerName: "Correo",
      description: "Correo electronico.",
      sortable: false,
      width: 160,
    },
    {
      field: "cellphone",
      headerName: "Celular",
      description: "Numero de contacto.",
      sortable: false,
      width: 130,
    },
  ];

  const respponseCallbackSearch = (response) => {
    if (response?.data?.length > 0) {
      setRows(response);
    }
  };

  const onKeyPressName = (event) => {
    let isEnter =
      event.which === 13 || event.keyCode === 13 || event.key === "Enter";
    if (isEnter) {
      getNode(
        `${urlBaseNode}customers/${name}`,
        respponseCallbackSearch
      );

    }
  };
  const fnRefresData = ()=>{
    setName("")
    getNode(
      `${urlBaseNode}customers/all-customers?page=${page > 0 ? page +1 : 1}&limit=${rowsPerPage}`,
      respponseCallback
    );
  }
  useEffect(() => {
    if (buscando) {
      updateInformation();
    }
  }, [urlBase, respponseCallback, buscando, updateInformation]);

  function isBirthdayInCurrentWeek(birthdayDate) {
    if (birthdayDate === null) {
      return false;
    }
    function getStartAndEndOfWeek() {
      const now = new Date();
      
      // Ajustamos la fecha para encontrar el lunes de la semana actual.
      const startOfWeek = new Date(now);
      startOfWeek.setDate(now.getDate() - now.getDay() + 1); // Lunes de la semana actual.
    
      // Ajustamos la fecha para encontrar el domingo de la semana actual.
      const endOfWeek = new Date(now);
      endOfWeek.setDate(now.getDate() - now.getDay() + 7); // Domingo de la semana actual.
    
      return {
        startOfWeek,
        endOfWeek,
      };
    }
    const { startOfWeek, endOfWeek } = getStartAndEndOfWeek();
    const birthday = new Date(birthdayDate);
    const iniWeek = new Date(startOfWeek.toISOString().slice(0, 10))
    const endWeek = new Date(endOfWeek.toISOString().slice(0, 10))
    const birthdayMonth = birthday.getMonth() +1;
    const birthdayDay = birthday.getDate() +1;
    const iniWeekMonth = iniWeek.getMonth() + 1
    const endWeekMonth = endWeek.getMonth() + 1

    const iniWeekDay = iniWeek.getDate() + 1
    const endWeekDay = endWeek.getDate() + 1

    if (birthdayMonth === iniWeekMonth && birthdayMonth === endWeekMonth && birthdayDay >= iniWeekDay && birthdayDay <= endWeekDay) {
      return true;
    }
  
    return false;
  }


  return (
    <Fragment>
      {rows?.data.length > 0 ? (
        <Fragment>
           <div className="w-full flex justify-end items-center my-2">
            <TextField
              id="outlined-basic"
              label="Buscar cliente"
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
              onKeyPress={(e) => onKeyPressName(e)}
            />
             <Button
              onClick={() => fnRefresData()}
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<AutorenewIcon />}
            >
              Refrescar
            </Button>
          </div>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell key={index} style={{ minWidth: column.width }}>
                        {column.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.data.length
                    ? rows?.data.map((row) => {
                        return (
                          <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell
                              classes={{
                                root: classes.columnActcion,
                              }}
                            >
                              {
                                <div
                                  className={
                                    row?.diference_days >=
                                    parseInt(row?.recurrence) + 3
                                      ? "bg-red-400"
                                      : row?.called2 === 1
                                      ? "bg-yellow-400"
                                      : null
                                  }
                                  style={{
                                    height: "49px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  <button
                                    onClick={() => handlerEdit(row)}
                                    className="m-0 mr-1"
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ marginLeft: 5 }}
                                  >
                                    <CreateIcon />
                                  </button>
                                  <button
                                    onClick={() => handlerDelet(row)}
                                    className="m-0 mr-1"
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ marginLeft: 5 }}
                                  >
                                    <DeleteForeverIcon />
                                  </button>
                                  <button
                                    onClick={() => handlerWpMessage(row)}
                                    className="m-0 mr-1"
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ marginLeft: 5 }}
                                  >
                                    <WhatsAppIcon />
                                  </button>
                                  <button
                                    onClick={() => handlerUpdate(row)}
                                    className="m-0 mr-1"
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ marginLeft: 5 }}
                                  >
                                    <PhoneInTalkIcon />
                                  </button>
                                </div>
                              }
                            </TableCell>
                            <TableCell>{row.full_name} { isBirthdayInCurrentWeek(row.birthday_date) ? '🎂':null}</TableCell>
                            <TableCell>{row.last_call}</TableCell>
                            <TableCell>{row.recurrence}</TableCell>
                            <TableCell>{row.document_type}</TableCell>
                            <TableCell>{row.customer_type}</TableCell>
                            <TableCell>{row.identification_number}</TableCell>
                            <TableCell
                              classes={{
                                root: classes.customAdress,
                              }}
                            >
                              {row.address_customer}
                            </TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.cellphone}</TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            {
              name === "" && <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows?.pagination?.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            }
            
          </Paper>
          {hiddeFormUpdate && (
            <FormUpdate
              updateInformation={() => {}}
              user={userInfo}
              setHiddeFormUpdate={setHiddeFormUpdate}
              setuserInfo={setuserInfo}
            />
          )}
        </Fragment>
      ) : (
        <NotFound />
      )}
    </Fragment>
  );
}
