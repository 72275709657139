import React, { useCallback, useEffect, useState, Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import CreateIcon from "@material-ui/icons/Create";
import { getNode, post, putNode } from "api/AsyncHttpRequest";
import { useSnackbar } from "notistack";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Swal from "sweetalert2";
import "./swal.css";
import Button from "@material-ui/core/Button";
import Spiner from '../../components/Spinner/Spinner';
import ResumeDownload from './components/ResumeDownload'
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getOnlyDate } from "utils/helpers";

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: 0,
  },
  columnActcion: {
    width: 80,
    padding: 0,
    display: 'flexf',
    justifyContent: 'center'
  },
  container: {
    minHeight: 650,
    height: "auto",
    width: "100%",
  },
  customAdress: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  button: {
    marginLeft: 10,
    height: 56,
  },
});

const Commands = () => {
  let hoy = new Date();
  const { enqueueSnackbar } = useSnackbar();
  const urlBase = process.env.REACT_APP_API_BASE_URL_NODE;
  const urlBrowser = window.location.href;
  const domain = urlBrowser.split("/")[2].split(".")[0];
  const classes = useStyles();
  const [buscando, setBuscando] = useState(true);
  const [guardando, setGuardando] = useState(false);
  const [neworders, setNeworders] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(1);
  const [inputQty, setinputQty] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [inputObservations, setinputObservations] = useState("");
  const [startDate, setStartDate] = React.useState(hoy);
  const [finaltDate, setfinaltDate] = React.useState(hoy);
  const [delivered, setDelivered] = React.useState("none");
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [start, setStart] = React.useState(0);
  const [limit, setLimit] = React.useState(30);
  const [limitCount, setLimitCount] = React.useState(30);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [usuario, setusuario] = React.useState("");
  const columns = [
    {
      field: "id",
      headerName: "No Factura",
      width: 150,
      description:
        "Que desea hacer, editar eliminar o guardar fecha de ultima llamada.",
      renderCell: (params) => (
        <div
          className={
            params?.row?.diference_days >= parseInt(params?.row?.recurrence) + 3
              ? "bg-red-400"
              : params?.row?.called2 === 1
              ? "bg-yellow-400"
              : null
          }
        >
          <button
            onClick={() => {}}
            className="m-0 mr-1"
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 5 }}
          >
            <CreateIcon />
          </button>
        </div>
      ),
    },
    { field: "full_name", headerName: "Nombre completo", width: 250 },

    {
      field: "recurrence",
      headerName: "Dirección",
      description: "Cada cuanto debo llamar a este cliente.",
      sortable: false,
      width: 140,
    },
    {
      field: "document_type",
      headerName: "Pedido",
      description: "Tipo de documento.",
      width: 90,
    },
    {
      field: "customer_type",
      headerName: "Ruta",
      description: "Tipo de cliente.",
      width: 90,
    },
    {
      field: "identification_number",
      description: "Numero de identificacion del cliente o negocio.",
      headerName: "Cantidad",
      width: 130,
    },
    {
      field: "address_customer",
      headerName: "Observacion",
      description: "Dirección ubicación del negocio.",
      type: "number",
      width: 200,
    },
    {
      field: "email",
      headerName: "Enviar",
      description: "Correo electronico.",
      sortable: false,
      width: 160,
    },
  ];

  const respponseCallback = useCallback(
    (response) => {
      setIsLoading(false)
      setSelectedRoute(1);
      setusuario("")
      setinputObservations('')
      if (response.length >= 0) {
        setBuscando(false);
        const newResponse = response.map((item) => {
          return { ...item, selected: false };
        });
        setNeworders(newResponse);
        enqueueSnackbar("Listado de ordenes actualizado", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else {
        enqueueSnackbar("Algo salio mal intentalo nuevamente", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    },
    [enqueueSnackbar]
  );

  const getDataUpdated = useCallback(() => {
    if (domain === "frutgo") {
      getNode(`${urlBase}external/pending-invoices?start=${start}&limit=${limitCount}&startDate=${getOnlyDate(
        startDate
      ).toString()}&endDate=${getOnlyDate(
        finaltDate
      ).toString()}&inRoute=${delivered}&client_name=${usuario}`, respponseCallback);
    }
  }, [urlBase, respponseCallback, domain, startDate, finaltDate, delivered, limitCount, usuario, start]);

  useEffect(() => {
    if (buscando && guardando === false) {
      getDataUpdated();
    }
  }, [buscando, getDataUpdated, guardando]);
  const handlerViewItem = async (items) => {
    const tableHtml = `
        <table>
          <thead>
            <tr>
              <th>Producto</th>
              <th>Cantidad</th>
            </tr>
          </thead>
          <tbody>
            ${items
              .map(
                (row) => `
              <tr key=${row.id}>
                <td component="th" align="left">${row.name}</td>
                <td align="rigth">${row.quantity}</td>
              </tr>
            `
              )
              .join("")}
          </tbody>
        </table>
      `;
    Swal.fire({
      title: "Productos",
      html: tableHtml,
      focusConfirm: false,
      showCloseButton: false,
      showCancelButton: false,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(result);
      }
    });
  };
  const handleChange = (event, row) => {
    setSelectedRoute(event.target.value);
    const newResponse = neworders.map((item) =>
      item.id === row.id
        ? { ...item, selected: event.target.value }
        : { ...item, selected: false }
    );
    setNeworders(newResponse);
  };

  const inputOnchange = (e, set)=>{
    set({[e.target.name]: e.target.value})
  }
  const respponseCallbackToSave = useCallback(
    (response) => {
      if (response.status === 200 || response.message ==="DELIVERED_UPDATE_SUCCESS" ) {
        setGuardando(false);
        getDataUpdated()
        enqueueSnackbar("Se ha enviado a ruta correctamente", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else {
        enqueueSnackbar("Algo salio mal intentalo nuevamente", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    },
    [enqueueSnackbar, getDataUpdated]
  )

  const handlerSubmit = (data)=>{
    setIsLoading(true)
    setGuardando(true)
    if (delivered === 'none') {
      const dataToSave = {
      full_name: data.client.name,
      phone: data.client.phonePrimary || "",
      address: data.client.address.address || "",
      quantity: inputQty.quantity || "",
      total: data.total,
      pay: "",
      observation: inputObservations.observations || "",
      observation_del: "",
      route: selectedRoute,
      delivered: "NO",
      id_invoice: data.id
    }
    post(`${urlBase}external/create-route`, dataToSave, respponseCallbackToSave)
    } else if(delivered === 'NO') {
      const observation = data.observation
      const mewObservation = inputObservations.observations !== undefined ? inputObservations.observations : observation
      const dataToSave = {
        quantity: inputQty.quantity || data.quantity || "",
        observation: mewObservation,
        route: selectedRoute,
        id_invoice: data.id
      }
      putNode(`${urlBase}external/update-data`, dataToSave, respponseCallbackToSave)
    }
    
    
  }

  const handleChangeini = (date) => {
    setStartDate(date);
  };
  const handleFinalChange = (date) => {
    setfinaltDate(date);
  };

  const fnGetData = ()=>{
    if (domain === "frutgo") {
      setIsLoading(true)
      getNode(`${urlBase}external/pending-invoices?start=${start}&limit=${limitCount}&startDate=${getOnlyDate(
        startDate
      ).toString()}&endDate=${getOnlyDate(
        finaltDate
      ).toString()}&inRoute=${delivered}&client_name=${usuario}`, respponseCallback);
    }
  }

  const handleSelectChange = (event) => {
    setDelivered(event.target.value);
  };

  const handleChangeRowsPerPage = React.useCallback(
    (event) => {
      setIsLoading(true)
      const updatedRowsPerPage = parseInt(event.target.value, 10);
      setLimit(updatedRowsPerPage);
      setLimitCount(updatedRowsPerPage)
      setRowsPerPage(rowsPerPage + limit)
      getNode(`${urlBase}external/pending-invoices?start=${start}&limit=${updatedRowsPerPage}&startDate=${getOnlyDate(
        startDate
      ).toString()}&endDate=${getOnlyDate(
        finaltDate
      ).toString()}&inRoute=${delivered}&client_name=${usuario}`, respponseCallback);
    },
    [delivered, finaltDate, startDate, respponseCallback, urlBase, start, limit, rowsPerPage, usuario],
  );

  const handleChangePage = React.useCallback(
    (event, newPage) => {
      setPage(newPage );
      setCount(count + 1)
      const numEmptyRows = newPage > 0 ? Math.max(0, (Math.floor((newPage * rowsPerPage + 1) / 30) * 30 + 1)) : 0;
      setLimit(numEmptyRows + limitCount);
      getNode(`${urlBase}external/pending-invoices?start=${numEmptyRows}&limit=${limitCount}&startDate=${getOnlyDate(
        startDate
      ).toString()}&endDate=${getOnlyDate(
        finaltDate
      ).toString()}&inRoute=${delivered}&client_name=${usuario}`, respponseCallback);

    },
    [ delivered, finaltDate, startDate, urlBase, respponseCallback, count, limitCount, rowsPerPage,usuario],
  );

const fnCreateOrdermanual = async ()=>{
  const { value: formValues } = await Swal.fire({
    title: 'CREAR ENTREGA',
    html:'<div style={{display: "flex", flexDirection: "column"}}>'+
      '<input id="swal-input1" class="swal2-input" placeholder="Nombre">' +
      '<input id="swal-input2" class="swal2-input" placeholder="Dirección">'+
      '<input id="swal-input3" class="swal2-input" placeholder="Teléfono">'+
      '<input id="swal-input4" class="swal2-input" placeholder="Cantidad">'+
      '<input id="swal-input5" class="swal2-input" placeholder="Observaciones">'+
      '<input id="swal-input7" class="swal2-input" placeholder="Total">'+
      '<br>' +
      ` <select
          id="swal-input6"
          value={row.selected}
          onChange={(e) => handleChange(e, row)}
          style={{display: "flex", width: "57%"}}
        >
          <option value=1>Ruta 1</option>
          <option value=2>Ruta 2</option>
          <option value=3>Ruta 3</option>
          <option value=4>Ruta 4</option>
        </select>`+
      '</div>',
      
    focusConfirm: false,

    preConfirm: () => {
      return [
        document.getElementById('swal-input1').value,
        document.getElementById('swal-input2').value,
        document.getElementById('swal-input3').value,
        document.getElementById('swal-input4').value,
        document.getElementById('swal-input5').value,
        document.getElementById('swal-input6').value,
        document.getElementById('swal-input7').value,
        document.getElementById('swal-input6').classList.add('swal2-select')
      ]
    }
  })
  
  if (formValues) {
    setIsLoading(true)
    const obj = Object.fromEntries(
      formValues.map((value, index) => [index, value])
    );
    const newObj = Object.keys(obj).reduce((acc, key) => {
      const newKey = `valor${parseInt(key)+1}`;
      acc[newKey] = obj[key];
      return acc;
    }, {});
    const min = 900000;
    const max = 999999;
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    const dataToSave = {
      address: newObj.valor2,
      delivered: 'NO',
      full_name: newObj.valor1,
      id_invoice: randomNum.toString(),
      observation: newObj.valor5,
      observation_del: '',
      pay: '',
      phone: newObj.valor3,
      quantity: newObj.valor4,
      route: parseInt(newObj.valor6),
      total: parseInt(newObj.valor7),
    }
    post(`${urlBase}external/create-route`, dataToSave, respponseCallbackToSave)
  }
}
  return (
    <div>
      {isLoading && (
        <div className="form-container__loading-overlay">
          <Spiner />
        </div>
      )}
      <div className="my-8 w-full border-b-2 border-purple-600 pb-5">
        <ResumeDownload setIsLoading={setIsLoading}/>
      </div>
      <div className="flex w-full justify-between px-5 mb-3">
        <div className="flex sm:w-1/2 mb-4 items-center">
          <label className="form-label font-semibold pr-2">Fecha</label>
          <DatePicker
            selected={startDate}
            onChange={handleChangeini}
            locale="es"
            className="date-picker"
          />
          {/* <label className="form-label font-semibold pr-2">Fecha Final</label>
          <DatePicker
            selected={finaltDate}
            onChange={handleFinalChange}
            locale="es"
            className="date-picker"
          /> */}
           <div className="mr-5 ml-5">
            <label htmlFor="delivered">Entregado</label>
            <select
              id="delivered"
              value={delivered}
              onChange={handleSelectChange}
            >
              <option value="SI">SI</option>
              <option value="NO">NO</option>
              <option value="none">Ninguno</option>
            </select>
          </div>
           <Button
              onClick={() => fnGetData()}
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: "5px", width: '100%' }}
            >
              Actualizar
            </Button>
        </div>
        <div className="flex flex-row items-center">
        <label className="form-label font-semibold pr-2">Usuario</label>
          <input className="h-10 border-2 border-gray-400 rounded-md pl-3" placeholder="Nombre del usuario" value={usuario} onChange={(e)=> setusuario(e.target.value)} />
        <Button
              onClick={() => fnGetData()}
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: "5px", width: '100%' }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => fnCreateOrdermanual()}
              type="submit"
              variant="contained"
              color="tertiary"
              style={{ marginLeft: "5px", width: '100%' }}
            >
              Crear
            </Button>
        </div>
      </div>
        
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead style={{position: 'relative', zIndex: 0}}>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell key={index} style={{ minWidth: column.width }}>
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {neworders.length
                ? neworders.map((row) => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.client.name}</TableCell>
                        <TableCell>{row.client.address.address}</TableCell>
                        <TableCell
                          classes={{
                            root: classes.columnActcion,
                          }}
                        >
                          {
                            <button
                              onClick={() => handlerViewItem(row.items)}
                              className="m-0 mr-1"
                              variant="contained"
                              color="primary"
                              size="small"
                              style={{ marginLeft: 5 }}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                        </TableCell>
                        <TableCell>
                          <select
                            value={row.selected}
                            onChange={(e) => handleChange(e, row)}
                          >
                            <option value={1}>Ruta 1</option>
                            <option value={2}>Ruta 2</option>
                            <option value={3}>Ruta 3</option>
                            <option value={4}>Ruta 4</option>
                          </select>
                        </TableCell>
                        <TableCell>
                          <input placeholder="Cantidad" name="quantity" onChange={(e)=> inputOnchange(e, setinputQty)} defaultValue={delivered !== 'none' ? row.quantity : ""} />
                        </TableCell>
                        <TableCell>
                          <input placeholder="Observaciones" name="observations" onChange={(e)=> inputOnchange(e, setinputObservations)} defaultValue={delivered !== 'none' ? row.observation : ""}/>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={()=>handlerSubmit(row)}
                          >
                            ENVIAR
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {
          <TablePagination
            rowsPerPageOptions={[ 30]}
            component="div"
            count={limit + 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      </Paper>
    </div>
  );
};

export default Commands;
