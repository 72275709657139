import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { put, getNode, putNode } from "api/AsyncHttpRequest";
import getDate from "utils/helpers";
import CachedIcon from "@material-ui/icons/Cached";
import { useSnackbar } from "notistack";
import { Fragment } from "react";
import NotFound from 'components/NotFound/NotFound';
import Spiner from '../../components/Spinner/Spinner';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    backgroundColor: "#F5F5F5",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function CardOrders({ setViewDetails }) {
  const { enqueueSnackbar } = useSnackbar();
  let hoy = new Date();
  const classes = useStyles();
  const [neworders, setNeworders] = useState([]);
  const [valueToGet, setvalueToGet] = useState(localStorage.getItem('valtosearch') || 1);
  const urlBrowser = window.location.href;
  const domain = urlBrowser.split("/")[2].split(".")[0];
  const urlBase = process.env.REACT_APP_API_BASE_URL;
  const urlBaseNode = process.env.REACT_APP_API_BASE_URL_NODE;
  const [buscando, setBuscando] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const cbResponseByOrder = (result, value) => {
    if (result) {
      setViewDetails({ view: true, data: result, items: [] });
    }
  };

  const handlerOpenOrder = (value) => {
    getNode(
      `${urlBaseNode}external/get-invoice-route/${value.id_invoice}`,
      cbResponseByOrder,
      value
    );
  };
  const callbackSaveOrder = (response) => {
    if (response?.data?.status_code === 200) {
      enqueueSnackbar("Orden de pedidos actualizado", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } else {
      enqueueSnackbar(
        "Algo salio mal al querer actualizar el orden, intentalo nuevamente",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    }
    getDataUpdated();
  };

  const updateDate = (value) => {
    put(
      `${urlBase}bussinessorders/api/bussinessorders/neworder`,
      { id: parseInt(value.draggableId), order_date: getDate(hoy) },
      callbackSaveOrder
    );
  };
  function updatePositions(array, initOrders) {
    // Ordenar el arreglo por la posición
    array.sort((a, b) => a.position - b.position);
  
    // Actualizar las posiciones
    for (let i = 0; i < array.length; i++) {
      array[i].position = initOrders[0].position + i;
    }
  
    return array;
  }
  
  const respponseCallback = useCallback(
    (response) => {
      setIsLoading(false)
      if (response.length >= 0) {
        setBuscando(false);
        setNeworders(response);
        enqueueSnackbar("Listado de ordenes actualizado", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else {
        enqueueSnackbar("Algo salio mal intentalo nuevamente", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    },
    [enqueueSnackbar]
  );

  const cbnewPositionFormated = (response)=>{
    getNode(`${urlBaseNode}external/get-all-routes-by-route/${valueToGet}`, respponseCallback);
  }

  const handleOnDragEnd = (result) => {
    // updateDate(result);
    if (!result.destination) return;
    const items = Array.from(neworders);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const newOrder = items.map((item)=> {return {id: item.id, position: item.position}})
    const oldIds = neworders.map(obj => obj.id);
    const idMap = oldIds.reduce((acc, cur, index) => {
      acc[cur] = newOrder[index].id;
      return acc;
    }, {});
    const sortedNewPosition = newOrder.sort((a, b) => a.position - b.position);
    const newPositionFormated = sortedNewPosition.map(obj => {
      return {id: idMap[obj.id], position: obj.position};
    });
    setIsLoading(true)
    putNode(`${urlBaseNode}external/update-positions`, newPositionFormated, cbnewPositionFormated)
  }; 

  const getDataUpdated = useCallback((val) => {
    setIsLoading(true)
    if (domain === "frutgo") {
      getNode(`${urlBaseNode}external/get-all-routes-by-route/${val}`, respponseCallback);
    }
  }, [urlBaseNode, respponseCallback, domain]);

  useEffect(() => {
    if (buscando) {
      getDataUpdated(valueToGet);
    }
  }, [buscando, getDataUpdated, valueToGet]);

  const [rows, setRows] = useState([
    { id: 1, name: "Row 1" },
    { id: 2, name: "Row 2" },
    { id: 3, name: "Row 3" },
    { id: 4, name: "Row 4" },
  ]);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const dragIndex = e.dataTransfer.getData("text");
    const dragRow = rows[dragIndex];
    let newRows = [...rows];
    newRows.splice(dragIndex, 1);
    newRows.splice(index, 0, dragRow);
    setRows(newRows);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const fnGetRoutesByRoute =(val)=>{
    setIsLoading(true)
    localStorage.setItem('valtosearch', val)
    setvalueToGet(val)
    getDataUpdated(val)
  }

  return (
    <Fragment>
       {isLoading && (
        <div className="form-container__loading-overlay">
          <Spiner />
        </div>
      )}
      <div className="flex flex-row w-full justify-between">
        <div className="w-full flex justify-start">
          {" "}
          <Button
            onClick={()=> fnGetRoutesByRoute(1)}
            type="submit"
            variant="contained"
            color="secondary"
            style={{marginLeft: '5px'}}
          >
            R1
          </Button>
          <Button
            onClick={()=> fnGetRoutesByRoute(2)}
            type="submit"
            variant="contained"
            color="secondary"
            style={{marginLeft: '5px'}}
          >
            R2
          </Button>
          <Button
            onClick={()=> fnGetRoutesByRoute(3)}
            type="submit"
            variant="contained"
            color="secondary"
            style={{marginLeft: '5px'}}
          >
            R3
          </Button>
          <Button
            onClick={()=> fnGetRoutesByRoute(4)}
            type="submit"
            variant="contained"
            color="secondary"
            style={{marginLeft: '5px'}}
          >
            R4
          </Button>
        </div>

        <div className="w-full flex justify-end">
          {" "}
          <Button
            onClick={()=>getDataUpdated(localStorage.getItem('valtosearch') || 1)}
            type="submit"
            variant="contained"
            color="primary"
          >
            <CachedIcon />
            Actualizar
          </Button>
        </div>
      </div>

      <div>
        {/* <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
      {rows.map((row, index) => (
        <tr
          key={row.id}
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDrop={(e) => handleDrop(e, index)}
          onDragOver={handleDragOver}
        >
          <td>{row.id}</td>
          <td>{row.name}</td>
        </tr>
      ))}
      </tbody>
    </table> */}
    {
      neworders.length ?  <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <div
                className="characters"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {neworders?.sort((a, b) => a.position - b.position).map(
                  ({ id_invoice, full_name, phone, address, observation}, index) => {
                    return (
                      <Draggable
                        key={id_invoice}
                        draggableId={id_invoice}
                        index={index}
                      >
                        {(provided) => (
                          <Card
                            className={`w-72 m-5 ${classes.root}`}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <CardContent>
                              <Typography
                                className="truncate"
                                variant="h5"
                                component="h3"
                              >
                                {full_name}
                              </Typography>
                              <Typography
                                className={classes.pos}
                                color="textSecondary"
                              >
                                {phone}
                              </Typography>
                              <Typography variant="body2" component="p">
                                {address}
                              </Typography>
                              {
                                observation !== "" ? <Typography variant="body2" component="p" className="bg-red-800 rounded-md p-2 text-white">
                                {observation}
                              </Typography> : null
                              }
                              
                            </CardContent>
                            <CardActions>
                              <Button
                                size="small"
                                onClick={() =>
                                  handlerOpenOrder({
                                    id_invoice,
                                    full_name,
                                    phone,
                                    address,
                                  })
                                }
                              >
                                Entregar
                              </Button>
                            </CardActions>
                          </Card>
                        )}
                      </Draggable>
                    );
                  }
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext> :<NotFound />
    }
      
      </div>
    </Fragment>
  );
}
