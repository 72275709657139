import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import getDate from 'utils/helpers';
import { post } from 'api/AsyncHttpRequest';
import ButtonUploadProducts from './ButtonUploadProducts';
import { useSnackbar } from 'notistack';

export default function FormCreateProduct() {
  let hoy = new Date();
  const { enqueueSnackbar } = useSnackbar();
  const urlSaveNewCustomer = process.env.REACT_APP_API_BASE_URL;
  const { register, handleSubmit } = useForm();
  const [diabledButton, setDiabledButton] = useState(false);
  const onSubmit = (data) => {
    setDiabledButton(true);
    let newData = {
      creation_date: getDate(hoy),
      name_product: data.name_product,
      sku: data.sku,
      photo_link:
        data.photo_link ||
        'https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Imagen_no_disponible.svg/1200px-Imagen_no_disponible.svg.png',
      descriptionProd: data.descriptionProd,
    };

    post(
      `${urlSaveNewCustomer}products/api/products/post`,
      newData,
      callbackSaveCustomer
    );
  };

  const callbackSaveCustomer = (response) => {
    setDiabledButton(false);
    if (response?.data?.data === true) {
      enqueueSnackbar('Excelente producto creado exitosamente!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    } else {
      enqueueSnackbar('Algo salio mal, intentalo nuevamente', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  return (
    <div className="p-10">
      <ButtonUploadProducts />
      <form
        className="flex flex-wrap justify-center items-center border-2 p-5 border-gray-500 rounded-xl"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mx-2 my-2">
          <input
            className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
            placeholder="Nombre"
            {...register('name_product', { required: true, maxLength: 50 })}
          />
        </div>
        <div className="mx-2 my-2">
          <input
            className="border-2 border-gray-400  w-56 rounded-md m-1 text-lg p-1"
            placeholder="SKU"
            {...register('sku', {
              required: true,
              maxLength: 20,
            })}
          />
        </div>
        <div className="mx-2 my-2">
          <input
            className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
            placeholder="Url imagen"
            {...register('photo_link', {
              maxLength: 200,
            })}
          />
        </div>
        <div className="mx-2 my-2">
          <input
            className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
            placeholder="Descripcion"
            label="Descripcion"
            {...register('descriptionProd', { maxLength: 200 })}
          />
        </div>

        <div className="mx-2 my-2">
          <Button
            disabled={diabledButton}
            type="submit"
            variant="contained"
            color="primary"
          >
            Guardar
          </Button>
        </div>
      </form>
    </div>
  );
}
