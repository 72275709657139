import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import FormOrder from '../components/FormOrder';
import InputGetuserController from './InputGetuserController';
import { get, post } from 'api/AsyncHttpRequest';
import ErrorLabel from 'components/Labels/ErrorLabel';
import FoundValue from 'components/Labels/FoundValue';
import getDate, {
  isEmpty,
  isEnterKey,
  calculatefinalcost,
} from 'utils/helpers';
import { useOrderInformations } from '../store/useOrderInformations';
import ProductSelect from '../components/ProductSelect';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CommonContext from 'context/context';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
export default function FormOrderController() {
  const {
    customerInformation,
    setcustomerInformation,
    noCustomer,
    setNoCustomer,
    notProduct,
    setnotProduct,
    productsList,
    setProductsList,
    viewSearchProduct,
    setViewSearchProduct,
    customerType,
    setCustomerType,
    qty,
    setQty,
    detailInvoice,
    setDetailInvoice,
    numberOrder,
    setNumberOrder,
  } = useOrderInformations();
  let history = useHistory();
  let hoy = new Date();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useContext(CommonContext);
  const objectLabels = {
    title: t('orders.tittle_methods'),
    option1: t('orders.option1_methods'),
    option2: t('orders.option2_methods'),
    option3: t('orders.option3_methods'),
  };
  const [valueSelected, setvalueSelected] = useState({});
  const [totalInv, setTotalInv] = useState(0);
  const { register, handleSubmit } = useForm();
  const [customersGet, setCustomersGet] = useState([]);
  const [getDataOrder, setGetDataOrder] = useState(true);
  const urlBase = process.env.REACT_APP_API_BASE_URL;

  const handlerChange = (event) => {
    const name = event.target.name;
    setvalueSelected({
      [name]: event.target.value,
    });
  };
  const callbackResponseCustomer = (data) => {
    if (data?.length > 0) {
      setNoCustomer('');
      setCustomersGet(data);
    } else {
      setNoCustomer('Cliente no encontrado');
    }
  };
  const onchangeGetCustomer = (event) => {
    if (isEnterKey(event)) {
      if (!isEmpty(event.target.value)) {
        get(
          `${urlBase}customer/api/customers/${event.target.value}`,
          callbackResponseCustomer
        );
        return;
      } else {
        setNoCustomer('El campo no puede estar vacio');
        return;
      }
    }
  };
  const callbackResponseGetProducts = (data) => {
    setProductsList(data);
  };
  const handlerSearchProduct = (e) => {
    if (isEnterKey(e)) {
      if (!isEmpty(e.target.value)) {
        get(
          `${urlBase}products/api/productsinput/${e.target.value}`,
          callbackResponseGetProducts
        );
      } else {
        setnotProduct('El campo no puede estar vacio');
        return;
      }
    }
  };
  const callbackSaveOrder = (response) => {
    if (response?.data?.data === true) {
      get(
        `${urlBase}bussinessorders/api/bussinessordersforids/${
          numberOrder + 1
        }`,
        callbackGetOrder
      );
    }
  };
  const callbackGetOrder = (response) => {
    if (response.length >= 0) {
      saveDetalleFactura(response[0]?.id_order);
    }
  };

  const onSubmit = ({ data }) => {
    const dataSave = {
      id_customer: parseInt(customerInformation?.id),
      id_employee: 1,
      invoice_number: data?.imbox_num || '',
      observations: data?.observation || '',
      order_date: getDate(hoy),
      order_number: numberOrder + 1,
      payment_method: valueSelected?.payment_method,
      total_invoice: 0,
    };

    if (customerInformation?.id && detailInvoice?.length > 0) {
      if (valueSelected?.payment_method !== undefined) {
        post(
          `${urlBase}bussinessorders/api/bussinessorders/post`,
          dataSave,
          callbackSaveOrder
        );
      } else {
        enqueueSnackbar('Debe seleccionar un metodo de pago', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    } else {
      enqueueSnackbar('Debe seleccionar un cliente y productos', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };
  const saveDetalleFactura = (id) => {
    let num = 0;
    let data = detailInvoice.map((item) => ({
      id_bussiness_order: parseInt(id),
      id_product: item?.id,
      quantity_products: parseInt(item.qty),
      status_product: 1,
      price_product: parseInt(item.price),
      subtotal: item.qty * item.price,
    }));

    for (const i of data) {
      num = num + 1;
      post(`${urlBase}bussinessorders/api/bussinessordersdetails/post`, i);
    }
    if (num === data.length) {
      enqueueSnackbar('Orden creada exitosamente', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      history.push('/home');
    }
  };
  const handlerSelectCustmer = (dataSelected) => {
    setViewSearchProduct(true);
    setCustomersGet([]);
    setcustomerInformation(dataSelected);
    setCustomerType(dataSelected?.customer_type);
  };
  const totalInvoice = (customDetail) => {
    setTotalInv(customDetail.reduce((a, b) => a + b?.qty * b?.price, 0));
  };
  const handlerAddProductDetail = (item, price, cb) => {
    const customDetail = [
      ...detailInvoice,
      {
        id: item.sku,
        name_product: item.producto,
        qty: qty,
        price: parseInt(price),
      },
    ];
    setDetailInvoice(customDetail);
    cb();
    totalInvoice(customDetail);
  };

  const handlerQuitarProducto = (row) => {
    const nuevoDetalle = detailInvoice.filter(
      (producto) => producto.id !== row.id
    );
    setDetailInvoice(nuevoDetalle);
    // let subTotal = total - parseInt(row.price)
    // setTotal(subTotal)
  };
  const callbackResponseGetOrder = useCallback(
    (response) => {
      setGetDataOrder(false);
      setNumberOrder(
        response.length === 0 ? 1 : parseInt(response[0]?.id_order) + 1
      );
    },
    [setNumberOrder]
  );

  useEffect(() => {
    if (getDataOrder) {
      get(
        `${urlBase}bussinessorders/api/bussinessordersforid`,
        callbackResponseGetOrder
      );
    }
  }, [getDataOrder, urlBase, callbackResponseGetOrder]);

  return (
    <div className="flex flex-col mt-10 px-3 md:mr-0 w-full md:w-auto md:flex-row border-2 p-4 md:p-5 border-gray-500 rounded-xl">
      <FormOrder handleSubmit={handleSubmit} onSubmit={onSubmit}>
        <FormOrder.Label>
          <p className="m-0 mt-2">{t('orders.order_number_tittle')} </p>
          <p className="m-0 mt-2 font-semibold">{` 00${numberOrder}`}</p>
        </FormOrder.Label>
        <FormOrder.Input
          nameLabel={t('orders.label_invoice')}
          placeHolder={t('orders.placeholder_invoice')}
          registername="imbox_num"
          register={register}
        />
        <FormOrder.Input
          nameLabel={t('orders.label_customer')}
          placeHolder={t('orders.placeholder_search')}
          registername="name_customer"
          register={register}
          onChangeValue={onchangeGetCustomer}
        />
        {noCustomer && <ErrorLabel noCustomer={noCustomer} />}
        {customerInformation?.full_name && (
          <FoundValue value={customerInformation?.full_name} />
        )}
        {viewSearchProduct && (
          <FormOrder.Input
            nameLabel={t('orders.label_product')}
            placeHolder={t('orders.placeholder_search')}
            onChangeValue={handlerSearchProduct}
          />
        )}
        {notProduct && <ErrorLabel noCustomer={notProduct} />}
        <ProductSelect
          handlerAddProductDetail={handlerAddProductDetail}
          viewSearchProduct={viewSearchProduct}
          productsList={productsList}
          customerType={customerType}
          setQty={setQty}
          calculatefinalcost={calculatefinalcost}
        />
        <InputGetuserController
          handlerSelectCustmer={handlerSelectCustmer}
          customersGet={customersGet}
        />
        <FormOrder.Select handlerChange={handlerChange} labels={objectLabels} />
        <FormOrder.Textarea
          nameLabel={t('orders.observations_label')}
          register={register}
          registername="observation"
        />
        <FormOrder.Button nameButton={t('orders.button_save')} />
      </FormOrder>
      <div>
        <p className="font-semibold text-blue-900">TOTAL ORDEN: {totalInv}</p>
        <TableContainer className="p-3" component={Paper}>
          <Table
            // className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Producto</TableCell>
                <TableCell align="right">Cantidad</TableCell>
                <TableCell align="right">Precion Unidad</TableCell>
                <TableCell align="right">Subtotal</TableCell>
                <TableCell align="right">Eliminar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detailInvoice.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.name_product}
                  </TableCell>
                  <TableCell align="right">{row.qty}</TableCell>
                  <TableCell align="right">{row.price}</TableCell>
                  <TableCell align="right">
                    {parseInt(row.qty * row.price)}{' '}
                  </TableCell>
                  <TableCell align="right">
                    <DeleteForeverIcon
                      className=""
                      style={{ fontSize: 20 }}
                      onClick={() => handlerQuitarProducto(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
