import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { put, get } from 'api/AsyncHttpRequest';
import Button from '@material-ui/core/Button';
import CachedIcon from '@material-ui/icons/Cached';
import getDate from 'utils/helpers';
import NotFound from 'components/NotFound/NotFound';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    backgroundColor: '#F5F5F5',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SortingOrders() {
  let hoy = new Date();
  const classes = useStyles();
  const [neworders, setNeworders] = useState([]);
  const [buscando, setBuscando] = useState(true);
  const urlBase = process.env.REACT_APP_API_BASE_URL;

  const callbackSaveOrder = () => {
    getDataUpdated();
  };

  const updateDate = (value) => {
    put(
      `${urlBase}bussinessorders/api/bussinessorders/neworder`,
      { id: parseInt(value.draggableId), order_date: getDate(hoy) },
      callbackSaveOrder
    );
  };
  const handleOnDragEnd = (result) => {
    updateDate(result);
    if (!result.destination) return;
    const items = Array.from(neworders);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setNeworders(items);
  };

  const respponseCallback = useCallback((response) => {
    setBuscando(false);
    setNeworders(response);
  }, []);

  const getDataUpdated = useCallback(() => {
    get(`${urlBase}bussinessorders/api/bussinessorders`, respponseCallback);
  }, [urlBase, respponseCallback]);

  useEffect(() => {
    if (buscando) {
      getDataUpdated();
    }
  }, [buscando, getDataUpdated]);

  return (
    <Fragment>
      <div className="w-full flex justify-end">
        <Button
          onClick={getDataUpdated}
          type="submit"
          variant="contained"
          color="primary"
        >
          <CachedIcon />
          Actualizar
        </Button>
      </div>

      <div className="flex">
        {neworders.length >= 0 ? (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {(provided) => (
                <div
                  className="characters w-full flex flex-col justify-center items-center"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {neworders?.map(({ idorder, name, address_cus }, index) => {
                    return (
                      <Draggable
                        key={idorder}
                        draggableId={idorder}
                        index={index}
                      >
                        {(provided) => (
                          <Card
                            className={`w-72 m-1 p-0 ${classes.root}`}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <CardContent style={{ padding: '5px' }}>
                              <p
                                className="w-full text-right"
                                style={{ fontSize: '10px', color: 'blue' }}
                              >
                                {index}
                              </p>
                              <Typography className="truncate" variant="h6">
                                {name}
                              </Typography>
                              <Typography variant="body2" component="p">
                                {address_cus}
                              </Typography>
                            </CardContent>
                          </Card>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <NotFound />
        )}
      </div>
    </Fragment>
  );
}
