import axios from 'axios';

export const get = async (url, callback) => {
  try {
    const data = await axios.get(url);
    callback(data?.data?.data);
  } catch (error) {
    callback(error);
  }
};
export const getAlegra = async (url, callback, value) => {
  try {
    const data = await axios.get(url, {
      headers: {
        Authorization: process.env.REACT_APP_API_TOKEN,
      },
    });

    // let transformData = data?.data.map((item) => {
    //   return {
    //     address_cus:
    //       item?.client?.address?.address +
    //       ' ' +
    //       (item?.client?.address?.city || '') +
    //       '' +
    //       (item?.client?.address?.department || ''),
    //     date_order: item?.date,
    //     idorder: item?.id,
    //     name: item?.client?.name,
    //     pago: item?.type ? 'TRA' : null,
    //     phone: item?.client?.mobile,
    //     total: item?.balance,
    //     items: item?.items?.map((product) => {
    //       return {
    //         id: product?.id,
    //         nameprod: product?.name,
    //         quantity: product?.quantity,
    //       };
    //     }),
    //   };
    // });
    callback(data, value);
  } catch (error) {
    callback(error);
  }
};
export const getNode = async (url, callback) => {
  try {
    const data = await axios.get(url, { headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },});
    callback(data?.data);
  } catch (error) {
    console.log(error);
    callback({data: {data: []}});
  }
};
export const getParams = async (url, params, callback) => {
  try {
    const data = await axios({
      method: 'POST',
      url: `${url}`,
      data: params,
    });
    // const data = await axios.post(url, params);
    callback(data?.data);
  } catch (error) {
    callback(error);
  }
};

export const post = async (url, formData = null, callBack = () => {}) => {
  try {
    const data = await axios.post(url, formData, { headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },});
    callBack(data);
  } catch (error) {
    if (callBack !== null) {
      callBack(error);
    }
  }
};
export const putNode = async (url, params, callback = () =>{}) => {
  try {
    const data = await axios.put(url, params, { headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },});
    callback(data?.data);
  } catch (error) {
    callback(error);
  }
};

export const put = async (url, params = null, callBack = () => {}) => {
  try {
    const data = await axios.put(url, params);
    callBack(data);
  } catch (error) {
    if (callBack !== null) {
      callBack(error);
    }
  }
};

export const patch = async (url, params = null, callBack = () => {}) => {
  try {
    const data = await axios.patch(url, params, { headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },});
    callBack(data);
  } catch (error) {
    if (callBack !== null) {
      callBack(error);
    }
  }
};

export function remove(url, params = null) {
  return axios.delete(url, params);
}
