import React, { useContext, useState } from 'react';
import CommonContext from 'context/context';
import CustomTabs from 'components/CustomTabs/CustomTabs';
import CreateQuotes from './controllers/CreateQuotes';
import ListQuotes from './controllers/ListQuotes';

const Index = () => {
  const [stateView, setStateView] = useState({
    form: false,
    list: true,
    update: false,
  });
  const { t } = useContext(CommonContext);
  const hanclderViewComponent = (newState) => {
    setStateView(newState);
  };
  return (
    <div className="mr-4">
      <CustomTabs>
        <CustomTabs.Button
          tittleButton={t('quotes.button_list_quotes')}
          fnOnclick={() =>
            hanclderViewComponent({
              form: false,
              list: true,
              update: false,
            })
          }
          activated={stateView?.list}
        />
        <CustomTabs.Button
          tittleButton={t('quotes.button_create')}
          fnOnclick={() =>
            hanclderViewComponent({
              form: true,
              list: false,
              update: false,
            })
          }
          activated={stateView?.form}
        />
      </CustomTabs>
      {stateView?.form && <CreateQuotes changeTab={setStateView} />}
      {stateView?.list && <ListQuotes />}
    </div>
  );
};

export default Index;
