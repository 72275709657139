import React from 'react';

import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

export default function NotFound() {
  return (
    <div className="flex flex-col justify-center items-center">
      <h2 className="text-2xl text-center">
        LO SENTIMOS NO HAY INFORMACION DE CLIENTES
      </h2>
      <SentimentVeryDissatisfiedIcon style={{ fontSize: '50px' }} />{' '}
    </div>
  );
}
