import { createContext } from 'react';
import { useTranslation } from 'react-i18next';

const ContextProvider = createContext({});

export function CommonContext({ children }) {
  const { t } = useTranslation('global');
  return (
    <ContextProvider.Provider value={{ t }}>
      {children}
    </ContextProvider.Provider>
  );
}

export default ContextProvider;
