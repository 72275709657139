import React, { useState, useCallback, useContext, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import { Link, useHistory } from 'react-router-dom';
import { getNode } from 'api/AsyncHttpRequest';
import clsx from 'clsx';
import HomeIcon from '@material-ui/icons/Home';

import {
  FaJenkins,
  // FaGrav,
  FaPalfed,
  FaPeopleCarry,
  FaChartLine,
  FaCartPlus,
  FaBook,
  FaSignOutAlt,
  FaConciergeBell
} from 'react-icons/fa';
import AuthContext from 'auth/ProvideAuth';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#4c1d95',
    color: '#FFFFFF',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: '#FFFFFF',
  },
  itemCustom: { color: '#ffffff' },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    backgroundColor: '#2C2C2C',
    color: '#ffffff',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#2C2C2C',
    color: '#ffffff',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  customListem: { padding: '0' },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    paddingTop: '35px',
    flexGrow: 1,
  },
}));

export default function Sidebar({ children }) {
  const urlBrowser = window.location.href;
  const domain = urlBrowser.split('/')[2].split('.')[0];
  const auth = useContext(AuthContext);
  const history = useHistory();
  const nameBusiness = localStorage.getItem('companyName') || 'Admin Company';
  const [count, setCount] = useState(0);
  const [buscando, setBuscando] = useState(true);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  // const urlAllCustomers = process.env.REACT_APP_API_BASE_URL;
  const urlBaseNode = process.env.REACT_APP_API_BASE_URL_NODE;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const respponseCallback = useCallback((response) => {
    setCount(parseInt(response?.length));
    setBuscando(false);
  }, []);

  const refresh = useCallback(() => {
    if (domain === 'frutgo'){
      getNode(`${urlBaseNode}external/get-all-routes`, respponseCallback);
    }
  }, [urlBaseNode, respponseCallback, domain]);

  // setInterval(() => {
  //   console.log('este');
  //   get(
  //     `${urlAllCustomers}bussinessorders/api/bussinessorderstotal`,
  //     respponseCallback
  //   );
  // }, 5000);

  const handlerCloseSessions = () => {
    auth.signout(() => history.push('/login'));
  };

  const sections = [
    {
      to: '/home',
      classListen: classes.customListem,
      icon: <HomeIcon className={classes.itemCustom} />,
      count: 0,
      primary: 'Inicio',
    },
    {
      to: '/orders',
      classListen: classes.customListem,
      icon: <FaCartPlus className={classes.itemCustom} />,
      count: 0,
      primary: 'Pedidos',
    },
    {
      to: '/customers',
      classListen: classes.customListem,
      icon: <FaJenkins className={classes.itemCustom} />,
      count: 0,
      primary: 'Clientes',
    },
    {
      to: '/products',
      classListen: classes.customListem,
      icon: <FaPalfed className={classes.itemCustom} />,
      count: 0,
      primary: 'Productos',
    },
    {
      to: '/commands',
      classListen: classes.customListem,
      icon: <FaConciergeBell className={classes.itemCustom} />,
      count: 0,
      primary: 'Comandas',
    },
    {
      to: '/deliver',
      classListen: classes.customListem,
      icon: <FaPeopleCarry className={classes.itemCustom} />,
      count: count || 0,
      primary: 'Entrega',
    },
    {
      to: '/quotes',
      classListen: classes.customListem,
      icon: <FaBook className={classes.itemCustom} />,
      count: 0,
      primary: 'Cotizaciones',
    },
    {
      to: '/statistics',
      classListen: classes.customListem,
      icon: <FaChartLine className={classes.itemCustom} />,
      count: 0,
      primary: 'Estadisiticas',
    },
  ];

  useEffect(() => {
    if (buscando) {
      refresh();
    }
  }, [refresh, buscando]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h4" noWrap>
            {nameBusiness.charAt(0).toUpperCase()+nameBusiness.slice(1)}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon className={classes.itemCustom} />
            )}
          </IconButton>
        </div>
        <Divider />

        <List>
          {sections.map(({ to, classListen, icon, primary, count }) => (
            <Link to={to} key={to}>
              <ListItem button className={classListen}>
                <ListItemIcon>
                  <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={count} color="secondary">
                      {icon}
                    </Badge>
                  </IconButton>
                </ListItemIcon>
                <ListItemText primary={primary} />
              </ListItem>
            </Link>
          ))}
        </List>

        <Divider className={classes.itemCustom} style={{ color: '#ffffff' }} />
        <List>
          <div type="button" className="w-full" onClick={handlerCloseSessions}>
            <ListItem button className={classes.customListem}>
              <ListItemIcon>
                <IconButton aria-label="show 4 new mails" color="inherit">
                  <FaSignOutAlt className={classes.itemCustom} />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary={'Cerrar'} />
            </ListItem>
          </div>
        </List>
      </Drawer>
      <main className={classes.content}>{children}</main>
    </div>
  );
}
