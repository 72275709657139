import {
  Home,
  ModuleOrders,
  ModuleCustomers,
  ModuleDeliver,
  ModuleStatistics,
  ModuleProducts,
  ModulePendingQuotes,
  ModuleCommands
} from './pages';

export const routes = [
  {
    path: '/home',
    label: 'Home',
    Component: Home,
  },
  {
    path: '/orders',
    label: 'Orders',
    Component: ModuleOrders,
  },
  {
    path: '/customers',
    label: 'Customers',
    Component: ModuleCustomers,
  },
  {
    path: '/deliver',
    label: 'Deliver',
    Component: ModuleDeliver,
  },
  {
    path: '/statistics',
    label: 'Statistics',
    Component: ModuleStatistics,
  },
  {
    path: '/products',
    label: 'Products',
    Component: ModuleProducts,
  },
  {
    path: '/quotes',
    label: 'Quotes',
    Component: ModulePendingQuotes,
  },
  {
    path: '/commands',
    label: 'Commands',
    Component: ModuleCommands,
  },
];
