import React from "react";
import { Line } from "@ant-design/charts";

const SalesMonth = ({ dataIn }) => {
  var data = [
    {
      year: "Enero",
      value: dataIn.Enero,
    },
    {
      year: "Febrero",
      value: dataIn.Febrero,
    },
    {
      year: "Marzo",
      value: dataIn.Marzo,
    },
    {
      year: "Abril",
      value: dataIn.Abril,
    },
    {
      year: "Mayo",
      value: dataIn.Mayo,
    },
    {
      year: "Junio",
      value: dataIn.Junio,
    },
    {
      year: "Julio",
      value: dataIn.Julio,
    },
    {
      year: "Agosto",
      value: dataIn.Agosto,
    },
    {
      year: "Septiembre",
      value: dataIn.Septiembre,
    },
    {
      year: "Octubre",
      value: dataIn.Octubre,
    },
    {
      year: "Noviembre",
      value: dataIn.Noviembre,
    },
    {
      year: "Diciembre",
      value: dataIn.Diciembre,
    },
  ];
  var config = {
    data: data,
    xField: "year",
    yField: "value",
    label: {},
    point: {
      size: 5,
      shape: "diamond",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: { showMarkers: false },
    state: {
      active: {
        style: {
          shadowColor: "yellow",
          shadowBlur: 4,
          stroke: "transparent",
          fill: "red",
        },
      },
    },
    theme: {
      geometries: {
        point: {
          diamond: {
            active: {
              style: {
                shadowColor: "#FCEBB9",
                shadowBlur: 2,
                stroke: "#F6BD16",
              },
            },
          },
        },
      },
    },
    interactions: [{ type: "marker-active" }],
  };
  return (
    <div className="w-full md:w-1/2 h-auto px-5 my-3">
      <h2 className="m-0 p-0 text-base text-blue-500 text-center">
        Resumen ventas por mes
      </h2>
      <Line {...config} />
    </div>
  );
};

export default SalesMonth;
