import React, { useState } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';

export default function ProductSelect({
  handlerAddProductDetail,
  viewSearchProduct,
  productsList,
  customerType,
  setQty,
  calculatefinalcost,
}) {
  const [customCoste, setCustomCoste] = useState(0);
  const resetCustomCoste = () => {
    setCustomCoste(0);
  };
  return (
    <div>
      {viewSearchProduct && (
        <div className="flex flex-wrap sm:flex-row md:flex-row lg:flex-row justify-start items-center my-2">
          <div className="flex flex-col justify-start items-start">
            {productsList &&
              productsList.map((item, index) => (
                <div key={index} className="flex flex-col w-full">
                  <p className="m-0 text-base text-left font-semibold">
                    {` ${item.producto} `}
                  </p>
                  <div className="flex flex-row justify-between">
                    <div className="my-1 sm:m-2 flex flex-col justify-end items-start">
                      <p className="m-0 text-lg flex flex-row justify-center items-center">
                        {`$ ${item.costo}`}
                      </p>
                      <p className="text-blue-800" style={{ fontSize: '10px' }}>
                        Costo
                      </p>
                    </div>
                    <div className="my-1 sm:m-2">
                      <input
                        className="border-2 border-gray-400 rounded-md w-20 text-xl"
                        name="price"
                        placeholder="$"
                        onChange={(e) => setCustomCoste(e.target.value)}
                        defaultValue={calculatefinalcost(
                          customerType,
                          item.costo
                        )}
                      />

                      <p className="text-blue-800" style={{ fontSize: '10px' }}>
                        Precio final
                      </p>
                    </div>
                    <div className="my-1 sm:m-2">
                      <input
                        type="number"
                        className="border-2 border-gray-400 rounded-md w-12 text-xl"
                        name="qty"
                        placeholder=""
                        onChange={(e) => setQty(parseInt(e.target.value))}
                      />
                      <AddCircleIcon
                        className=""
                        style={{ fontSize: 30 }}
                        onClick={() =>
                          handlerAddProductDetail(
                            item,
                            customCoste ||
                              calculatefinalcost(customerType, item.costo),
                            resetCustomCoste
                          )
                        }
                      />
                      <p className="text-blue-800" style={{ fontSize: '10px' }}>
                        Cantidad del producto
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
