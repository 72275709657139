import React, { useState, useEffect } from 'react';
import { get, put } from 'api/AsyncHttpRequest';
import { DataGrid } from '@material-ui/data-grid';
import CreateIcon from '@material-ui/icons/Create';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useForm } from 'react-hook-form';
import getDate from 'utils/helpers';
import Button from '@material-ui/core/Button';
import NotFound from 'components/NotFound/NotFound';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

export function FormUpdate({ data, setState, getDataUpdate }) {
  const { enqueueSnackbar } = useSnackbar();
  let hoy = new Date();
  const urlSaveNewCustomer = process.env.REACT_APP_API_BASE_URL;
  const { register, handleSubmit } = useForm();
  let sku = data?.sku;
  const callbackSaveCustomer = (response) => {
    if (response?.data?.data === true) {
      enqueueSnackbar('El producto se actualizo correctamente', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      setState({
        succes: true,
        error: false,
        change: false,
      });
      getDataUpdate();
    } else {
      enqueueSnackbar('Algo salio mal intentalo nuevamente', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      setState({
        succes: false,
        error: true,
        change: false,
      });
    }
  };

  const onSubmit = (data) => {
    let newData = {
      creation_date: getDate(hoy),
      name_product: data.name_product,
      sku: sku,
      photo_link:
        data.photo_link ||
        'https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Imagen_no_disponible.svg/1200px-Imagen_no_disponible.svg.png',
      descriptionProd: data.descriptionProd,
    };

    const updateProduct = window.confirm(
      'Quieres actualizar el producto ' + newData?.name_product
    );

    if (updateProduct) {
      put(
        `${urlSaveNewCustomer}products/api/products/update`,
        newData,
        callbackSaveCustomer
      );
    } else {
      // setDiabledButton(false);
      enqueueSnackbar('El producto no se actualizo', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  return (
    <>
      <form
        className="flex flex-wrap justify-center m-5 items-center border-2 p-5 border-gray-500 rounded-xl"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mx-2 my-2">
          <input
            className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
            defaultValue={data?.name_product}
            placeholder="Nombre"
            {...register('name_product', { required: true, maxLength: 50 })}
          />
        </div>
        <div className="mx-2 my-2">
          <input
            className="border-2 border-gray-400 text-gray-500 w-56 rounded-md m-1 text-lg p-1"
            defaultValue={data?.sku}
            placeholder={data?.sku}
            disabled
          />
        </div>
        <div className="mx-2 my-2">
          <input
            className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
            defaultValue={data?.photo_link}
            placeholder="Url imagen"
            {...register('photo_link', {
              maxLength: 200,
            })}
          />
        </div>
        <div className="mx-2 my-2">
          <input
            className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
            defaultValue={data?.descriptionProd}
            placeholder="Descripcion"
            label="Descripcion"
            {...register('descriptionProd', { maxLength: 200 })}
          />
        </div>

        <div className="mx-2 my-2">
          <Button type="submit" variant="contained" color="primary">
            Guardar
          </Button>
        </div>
      </form>
    </>
  );
}

export default function ListProducts() {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    viewFormUpdate: false,
    data: {},
    succes: false,
    error: false,
    change: false,
  });
  const urlAllProducts = process.env.REACT_APP_API_BASE_URL;
  const [rows, setRows] = useState([]);
  const [buscando, setBuscando] = useState(true);

  const respponseCallback = useCallback((response) => {
    setBuscando(false);
    setRows(response);
  }, []);

  const respponseCallbackEdit = (response) => {
    if (response.length === 1) {
      setState({ viewFormUpdate: true, data: response[0] });
      get(`${urlAllProducts}products/api/products`, respponseCallback);
    }
  };
  const respponseCallbackDelete = (response) => {
    if (response) {
      setState({ succes: true });
      enqueueSnackbar('Producto eliminado exitosamente', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      get(`${urlAllProducts}products/api/products`, respponseCallback);
    }
  };

  const handlerEdit = (e) => {
    setState({ change: true });
    get(`${urlAllProducts}products/api/productsid/${e}`, respponseCallbackEdit);
  };

  const handlerDelet = (params) => {
    const skuProduct = params?.row?.sku;
    const deleteProduct = window.confirm(
      'Quieres eliminar el producto con SKU' + skuProduct
    );

    if (deleteProduct) {
      put(
        `${urlAllProducts}products/api/products/delete`,
        {
          id: params?.row?.id,
          state_products: 0,
        },
        respponseCallbackDelete
      );
    } else {
      // setDiabledButton(false);
      enqueueSnackbar('El producto no se elimino', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  const columns = [
    {
      field: 'id',
      headerName: '',
      width: 150,
      description:
        'Que desea hacer, editar eliminar o guardar fecha de ultima llamada.',
      renderCell: (params) => (
        <div>
          <button
            onClick={() => handlerEdit(params.value)}
            className="m-0 mr-1"
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 5 }}
          >
            <CreateIcon />
          </button>
          <button
            onClick={() => handlerDelet(params)}
            className="m-0 mr-1"
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 5 }}
          >
            <DeleteForeverIcon />
          </button>
        </div>
      ),
    },
    {
      field: 'sku',
      headerName: 'SKU',
      width: 120,
    },
    { field: 'name_product', headerName: 'Nombre', width: 230 },
    {
      field: 'photo_link',
      headerName: 'Foto',
      type: 'number',
      width: 100,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="CRM"
          className="w-10 h-10"
          style={{ objectFit: 'cover' }}
        />
      ),
    },
    {
      field: 'descriptionProd',
      headerName: 'Descripcion',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 300,
    },
  ];

  const getDataUpdate = useCallback(() => {
    get(`${urlAllProducts}products/api/products`, respponseCallback);
  }, [urlAllProducts, respponseCallback]);

  useEffect(() => {
    if (buscando) {
      get(`${urlAllProducts}products/api/products`, respponseCallback);
    }
  }, [urlAllProducts, respponseCallback, buscando]);

  return (
    <div style={{ height: 400, width: '100%' }}>
      {rows?.length > 0 ? (
        <DataGrid rows={rows} columns={columns} pageSize={10} />
      ) : (
        <NotFound />
      )}
      {state?.viewFormUpdate && (
        <FormUpdate
          data={state?.data}
          setState={setState}
          getDataUpdate={getDataUpdate}
        />
      )}
    </div>
  );
}
