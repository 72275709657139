import React, { useEffect, useState } from 'react';
import { getNode, patch } from 'api/AsyncHttpRequest';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

function ListQuotes() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const urlBase_node = process.env.REACT_APP_API_BASE_URL_NODE;
  const [observationUpdate, setObservationUpdate] = useState('');
  const [idUpdate, setidUpdate] = useState('');
  const [getingData, setGetingData] = useState(true);
  const [quotesList, setQuotesList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const idUser = localStorage.getItem("id");
  const cbResponseQuotes = (response) => {
    setGetingData(false);
    setQuotesList(response);
  };

  const fnGet = () => {
    getNode(`${urlBase_node}quotes/by-user/${idUser}`, cbResponseQuotes);
  };
  
  useEffect(() => {
    getingData && fnGet();
  }, [getingData, fnGet]);

  const cbUpdateQuote = (data) => {
    if (data?.status === 200) {
      fnGet();
      enqueueSnackbar('Cotizacion actualizada correctamente', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      setidUpdate('');
      return;
    } else {
      enqueueSnackbar('Algo salio mal Intentalo nuevamente', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      return;
    }
  };
  const handlerDeleteQuote = (idUpdate) => {
    patch(
      `${urlBase_node}quotes/${idUpdate}`,
      { status: 'Closed' },
      cbUpdateQuote
    );
  };
  const handlerEditQuote = (observation) => {
    setObservationUpdate(observation?.observation);
    setidUpdate(observation?.id);
    return;
  };
  const onSubmit = (data) => {
    const dataSave = {
      observation: data?.observartion,
    };

    if (dataSave) {
      patch(`${urlBase_node}quotes/${idUpdate}`, dataSave, cbUpdateQuote);
    }
  };
  return (
    <div className="w-full flex flex-wrap">
      {quotesList.map((item) => (
        <div
          key={item.id}
          className="h-auto w-80 bg-indigo-100 mx-2 my-2 p-2 rounded-xl"
        >
          <h2 className="font-semibold text-xl">
            {item?.customers?.full_name}
          </h2>
          {idUpdate === item.id && (
            <form
              className="flex flex-wrap justify-center items-center border-2 p-1 mb-1 border-gray-500 rounded-xl"
              onSubmit={handleSubmit(onSubmit)}
            >
              {' '}
              <textarea
                className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
                placeholder="Detalle cotización"
                label="Observaciones"
                minLength={5}
                {...register('observartion', {
                  required: true,
                  minLength: 5,
                })}
                defaultValue={observationUpdate}
              />
              <button
                className="bg-green-900 p-1 px-3 rounded-md text-white mr-2"
                type="submit"
              >
                Guardar
              </button>
            </form>
          )}
          {idUpdate !== item.id && (
            <p className="text-base">{item.observation}</p>
          )}
          <p className="w-20 rounded-xl text-xs text-center font-semibold bg-green-500 text-white py-2">
            {item.status}
          </p>
          <div className="w-full flex justify-end">
            {idUpdate !== item.id && (
              <button
                className="bg-yellow-900 p-1 px-3 rounded-md text-white mr-2"
                onClick={() => handlerEditQuote(item)}
              >
                Editar
              </button>
            )}

            <button
              className="bg-indigo-900 p-1 px-3 rounded-md text-white"
              onClick={() => handlerDeleteQuote(item.id)}
            >
              Cerrar
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ListQuotes;
