import React from "react";
import { Liquid } from "@ant-design/charts";

const OrderResume = ({ data }) => {
  var config = {
    percent: data.done,
    outline: {
      border: 4,
      distance: 2,
    },
    wave: { length: 30 },
  };
  return (
    <div className="w-32 h-48 m-3 border-2 border-white rounded-2xl p-3">
      <h2 className="m-0 p-0 text-xs text-blue-500 text-center">
        Total pedidos entregados
      </h2>
      <Liquid className="text-blue-400 " {...config} />
    </div>
  );
};

export default OrderResume;
