import React from "react";
import { Pie } from "@ant-design/charts";

export default function StatisticsRound({ dataIn }) {
  var data = [
    {
      type: "< 10",
      value: parseInt(dataIn.status_ok),
    },
    {
      type: "Proximo",
      value: parseInt(dataIn.status_pending),
    },
    {
      type: "Urgente",
      value: parseInt(dataIn.status_urgent),
    },
  ];

  var config = {
    appendPadding: 10,
    data: data,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      color: "#FFFFFF",
      content: function content(_ref) {
        var percent = _ref.value;
        return percent;
      },
      style: {
        fontSize: 14,
        color: "#FFFFFF",
        textAlign: "center",
        height: "200px",
      },
    },
    interactions: [{ type: "element-active" }],
  };
  return (
    <div className="mx-8 w-80 h-80 border-2 border-white rounded-2xl py-2">
      <h2 className="m-0 p-0 text-lg text-blue-500 text-center">
        Resumen clientes contactados
      </h2>
      <Pie {...config} />
    </div>
  );
}
